import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// material-ui
import { styled } from '@mui/material/styles';
import { Container } from '@mui/system';

// project imports
import AppBar from './AppBar';
import Footer from './Footer';
import CreateApplicationPopUp from 'views/web-page/application/CreateApplicationPopUp';
import SubmittedApplicationPopUp from 'views/web-page/application/SubmittedApplicationPopUp';
import { CREATE_INTEREST, CREATE_APPLICATION } from 'store/slices/applicationSlice';
import CreateInterestPopUp from '../application/CreateInterestPopUp';

// ==============================|| SAMPLE PAGE ||============================== //

const HeaderWrapper = styled('div')(({ theme }) => ({
    paddingTop: 30,
    overflowX: 'hidden',
    overflowY: 'clip',
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.down('md')]: {
        paddingTop: 42
    }
}));

const WebPageWrapper = ({ children, hero, showApply, hideApply }) => {
    const dispatch = useDispatch();

    const [openApplyPopUp, setOpenApplyPopUp] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        if (showApply && !openApplyPopUp) {
            setOpenApplyPopUp(true);
        }
    }, [showApply, openApplyPopUp]);

    const handleApplyNow = () => {
        setOpenApplyPopUp(true);
    };

    const handleCloseApplyPopUp = ({ save, application }, reason) => {
        // debugger; // eslint-disable-line no-debugger
        if (reason && reason === 'backdropClick') return;
        setOpenApplyPopUp(false);
        if (showApply) {
            hideApply(false);
        }
        if (save && application.companyName) {
            const companyName = application.companyName.replace(/[^a-zA-Z0-9]/g, '');
            dispatch(CREATE_APPLICATION({ data: application, companyName }));
        }
    };

    const handleCloseInterestPopUp = ({ save, application }, reason) => {
        // debugger; // eslint-disable-line no-debugger
        if (reason && reason === 'backdropClick') return;
        setOpenApplyPopUp(false);
        if (showApply) {
            hideApply(false);
        }

        if (save && application.companyName) {
            const companyName = application.companyName.replace(/[^a-zA-Z0-9]/g, '');
            dispatch(CREATE_INTEREST({ data: application, companyName }));
        }
    };

    const handleConfirmationClose = () => {
        setShowConfirmation(false);
    };

    return (
        <>
            <AppBar handleApply={handleApplyNow} />
            {hero ? (
                <Container sx={{ mt: 6 }} disableGutters maxWidth="xl">
                    <HeaderWrapper>{hero}</HeaderWrapper>
                    {children}
                </Container>
            ) : (
                <Container sx={{ mt: 10 }} disableGutters maxWidth="xl">
                    {children}
                </Container>
            )}
            <Footer />
            <CreateApplicationPopUp open={openApplyPopUp || !!showApply} handleClose={handleCloseApplyPopUp} />
            {/* <CreateInterestPopUp open={openApplyPopUp || showApply} handleClose={handleCloseInterestPopUp} /> */}
            <SubmittedApplicationPopUp open={showConfirmation} handleClose={handleConfirmationClose} />
        </>
    );
};

WebPageWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element.isRequired]),
    hero: PropTypes.element,
    showApply: PropTypes.bool,
    hideApply: PropTypes.func
};

export default WebPageWrapper;
